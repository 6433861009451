body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

.text-title  {
  color: #292929;
  font-size: 6vw;
  text-align: left;
  font-weight: 400;
  margin-left: -2px;
  font-family: 'Oswald', sans-serif;
}

.text-subtitle {
  color: white;
  font-size: 3.5vw;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
  text-shadow: rgba(0, 0, 0, 0.69) 2px 2px 3px;
}

@media only screen and (max-width: 550px) {
  .text-title  {
    font-size: 8.5vh;
  }
  .text-subtitle {
    font-size: 3.5vh;
  }
}
